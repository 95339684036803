import React from "react";
// import logo from '@src/assets/images/logo/logo.png';
import ftsLogo from "@src/assets/images/logo/FTS_icon1.png";

export const LoaderComponent = () => {
  return (
    <div className="loader-component">
      <div
        id="loader"
        className="fallback-spinner app-loader"
        style={{ display: "none", width: "100vw" }}
      >
        <img className="fallback-logo" src={ftsLogo} height="60px" alt="logo" />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    </div>
  );
};

var pendingRequest = 0;
const showLoader = (loading) => {
  const loader = document.getElementById("loader");
  if (loader) {
    if (loading === true) {
      pendingRequest++;
      loader.classList.add("d-flex");
    } else {
      pendingRequest--;
      if (pendingRequest <= 0) {
        loader.classList.remove("d-flex");
      }
    }
  }
};

export default showLoader;
